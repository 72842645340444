import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Home', href: '/'},
  { name: 'About Us', href: '/about-us' },
  { name: 'Contact', href: '/contact' },
  { name: 'Careers', href: '/careers' },
]

const stats = [
  { id: 1, name: 'TOTAL FOLLOWERS.', value: '1.65M+' },
  { id: 2, name: 'PEOPLE REACHED IN 2022.', value: '155M+' },
  { id: 3, name: 'IMPRESSIONS IN 2022.', value: '3.57B+' },
  { id: 4, name: 'ENGAGEMENT RATE.', value: '12.23%' },
  { id: 5, name: 'COUNTRIES WORLDWIDE.', value: '165+' },
]

export default function Main() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const redirectToURL = (url) => {
    window.location.href = url;
  };
  
  return (
    <div>
      <div className="bg-black">
        <header className="absolute inset-x-0 top-0 z-50">
          <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Goalkeepers</span>
                <img
                  className="h-12 w-auto"
                  src="https://media.discordapp.net/attachments/1115622246774472765/1118566001517277346/header.png?width=676&height=676"
                  alt=""
                />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                  {item.name}
                </a>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a href="/submit" className="text-sm font-semibold leading-6 text-white">
                  Submit <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </nav>
          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50"/>
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
              <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">GOALKEEPERS</span>
                  <img
                    className="h-8 w-auto"
                    src="https://media.discordapp.net/attachments/1115622246774472765/1118566001517277346/header.png?width=676&height=676"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                  <a
                      href="/submit"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
        <div className="relative isolate overflow-hidden">
          <video autoPlay muted loop className="absolute inset-0 -z-10 h-full w-full object-cover video-player">
            <source
              src="https://cdn.discordapp.com/attachments/1115622246774472765/1118340767082106950/file.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
              <h1 className="text-lg font-goalkeepers-bold tracking-tight text-white sm:text-8xl italic">
                GOALKEEPERS.
              </h1>
              <p className="mt-6 text-lg sm:text-2xl leading-8 text-white">
              THE LARGEST GOALKEEPER COMMUNITY ON THE PLANET.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6"/>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-black pt-[3%]" />
          </div>
        </div>
      </div>
      <div className="bg-black py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <br />
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-26 w-full object-contain lg:col-span-1"
            src="https://media.discordapp.net/attachments/1115622246774472765/1118715076841590896/TIKTOK_LOGO_W_TEXT_FINAL.png?width=901&height=676"
            alt="TikTok"
            onClick={() => redirectToURL('https://www.tiktok.com/@goalkeepersttok')}
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-26 w-full object-contain lg:col-span-1"
            src="https://media.discordapp.net/attachments/1115622246774472765/1118715076187279371/INSTAGRAM_LOGO_W_TEXT_FINAL.png?width=901&height=676"
            alt="Instagram"
            onClick={() => redirectToURL('https://www.instagram.com/goalkeepers/')}
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-26 w-full object-contain lg:col-span-1"
            src="https://media.discordapp.net/attachments/1115622246774472765/1118715615356653629/FACEBOOK_LOGO_W_TEXT_FINAL.png?width=901&height=676"
            alt="Youtube"
            onClick={() => redirectToURL('https://www.youtube.com/@goalkeepersIG')}
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-26 w-full object-contain sm:col-start-2 lg:col-span-1"
            src="https://media.discordapp.net/attachments/1115622246774472765/1118715076485066852/SNAPCHAT_LOGO_W_TEXT_FINAL.png?width=901&height=676"
            alt="Snapchat"
            onClick={() => redirectToURL('https://example.com')}
            width={158}
            height={48}
          />
          <img
            className="col-span-2 col-start-2 max-h-26 w-full object-contain sm:col-start-auto lg:col-span-1"
            src="https://media.discordapp.net/attachments/1115622246774472765/1118715075801391104/FACEBOOK_LOGO_W_TEXT_FINAL.png?width=901&height=676"
            alt="Facebook"
            onClick={() => redirectToURL('https://example.com')}
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
    <div className="relative isolate overflow-hidden bg-black py-24 sm:py-32">
      <img
        src="https://media.discordapp.net/attachments/1115622246774472765/1118575654271717436/GOALKEEPERSAVE_LOW_OPACITY.png?width=1087&height=676"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover opacity-80 blur-sm"
      />
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div
          className="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10"
          aria-hidden="true"
        >
          <div
            className="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
          <h2 className="text-base font-semibold leading-8 text-blue-400">Our track record</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          THE LARGEST GOALKEEPER COMMUNITY ON THE PLANET.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-100">
          Goalkeepers is the perfect platform for brands and content creators who are looking to take their digital presence to the next level by reaching millions of people.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-10 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          {stats.map((stat) => (
            <div key={stat.id} className="flex flex-col gap-y-3 border-l border-white/80 pl-6">
              <dt className="text-sm leading-6">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl px-3 py-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-white">
            &copy; 2023 Goalkeeper Media.
          </p>
        </div>
      </div>
    </footer>
    </div>
  )
}
